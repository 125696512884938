import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"



export default _defineComponent({
  props: {
  onClick: Function,
  content: String,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock("button", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (__props.onClick && __props.onClick(...args))),
    class: "border border-blue-sky px-3 h-9 rounded-4xl text-sm ml-2"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})